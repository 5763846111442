// @flow
import React from 'react';
import styled from 'styled-components';
import Layout from '../components/layout';
import { withFrontMatter } from '../components/front-matter';
import type { FrontMatter } from '../utils/types';

const H2 = styled.h2`
  margin: 1.66667rem 0;
`;

const H3 = styled.h3`
  margin: 0.83333rem 0 1.66667rem 0;
`;

const H4 = styled.h4`
  margin: 0.83333rem 0 1.66667rem 0;
`;

const P = styled.p`
  margin-bottom: 0.83333rem;
`;

const Ul = styled.ul`
  margin-bottom: 0.83333rem;
`;

class Canyoneering_OtherCanyonRatingSystems extends React.Component<{
  frontMatter: FrontMatter,
}> {
  render() {
    const { frontMatter } = this.props;

    return (
      <Layout frontMatter={frontMatter} toc>
        <P>
          These are historic or alternate descriptions canyon rating systems I
          have found to be mildly entertaining.
        </P>
        <H2 id="allen">Canyoneering the Northern San Rafael Swell</H2>
        <H3 id="allen-technical">Technical Classification</H3>
        <ul>
          <li>
            <b>1</b> Canyon Hiking: Nontechnical, no rope required. Hiking
            mostly on established routes. Involves some scrambling with the
            occasional use of hands.
          </li>
          <li>
            <b>2</b> Basic Canyoneering: Scrambling, easy vertical or
            near-vertical climbing and/or down-climbing. Rope recommended for
            handlines, belays, lowering packs, and possible emergency use. Exit
            and/or retreat possible without ascending fixed ropes. Scrambling
            requiring the use of hands and arms for pulling yourself up.
          </li>
          <li>
            <b>3</b> Intermediate Canyoneering: Technical canyoneering and
            climbing. Routes may involve any combination of the following:
            Problem-solving. A basic knowledge of technical climbing. Rope and
            climbing hardware for single-pitch rappels and belays. Basic pothole
            escape techniques. Obvious natural anchors. Retreat up canyon will
            require ascending fixed ropes.
          </li>
          <li>
            <b>4</b> Advanced Canyoneering: In addition to intermediate
            canyoneering skills, you will require one or more of the following
            skills: Advanced free climbing. Difficult and/or exposed
            down-climbing. Climbing using direct aid. Multipitch rappels.
            Complex rope work (i.e. guided rappels, deviations, rebelays).
            Obscure or indistinct natural anchors. Advanced problem-solving and
            anchor-building. Advanced pothole escape techniques.
          </li>
        </ul>
        <H3 id="allen-water">Water volume/current</H3>
        <ul>
          <li>
            <b>A</b> Normally dry or very little water. Dry falls.
          </li>
          <li>
            <b>B</b> Normally has water with no current or light current, Still
            pools. Falls are dry or running at a trickle.
          </li>
          <li>
            <b>C</b> Normally has water with strong current. Waterfalls.
          </li>
        </ul>

        <H2 id="black">Canyoneering: How To Climb Series</H2>
        <H3 id="black-technical-rating">Technical Rating</H3>
        <ul>
          <li>
            <b>Class 1</b> Hiking; no ropework
          </li>
          <li>
            <b>Class 2</b> Basic canyoneering: scrambling; a rope may be used
            for easy climbing, handlines, and belays
          </li>
          <li>
            <b>Class 3</b> Intermediate canyoneering; rappelling; the canyon is
            not reversible without fixing ropes
          </li>
          <li>
            <b>Class 4</b> Advanced canyoneering; multipitch rappels; deviations
            and rebelays; exposed climbing; advanced ropework and problem
            solving
          </li>
        </ul>
        <H3 id="black-water-rating">Water Rating</H3>
        <ul>
          <li>
            <b>Class A</b> Dry or wading but no swimming
          </li>
          <li>
            <b>Class B</b> Swimming in still or near still water; wet
            disconnects (disconnecting from a rappel device while above or in
            the water); no or very minor current
          </li>
          <li>
            <b>Class C</b> Canyoneering in current and waterfalls
          </li>
        </ul>
        <H3 id="black-duration">Duration</H3>
        <ul>
          <li>
            <b>Grade I</b> A couple hours
          </li>
          <li>
            <b>Grade II</b> Half a day
          </li>
          <li>
            <b>Grade III</b> Most of a day
          </li>
          <li>
            <b>Grade IV</b> All of a long day
          </li>
          <li>
            <b>Grade V</b> All day and overnight
          </li>
          <li>
            <b>Grade VI</b> Multiple days and nights
          </li>
        </ul>
        <H3 id="black-risk">Risk</H3>
        <ul>
          <li>
            <b>R</b> More risk than average
          </li>
          <li>
            <b>X</b> Extreme risk; experts only
          </li>
        </ul>

        <H2 id="kelsey">
          Technical Slot Canyon Guide to the Colorado Plateau 2nd Edition
        </H2>
        <H3 id="kelsey-technical-class">Technical Class</H3>
        <P>
          Ranks the technicality of the terrain and the type of rope work
          required.
        </P>
        <Ul>
          <li>
            <b>1 Canyon Hiking</b> Nontechnical, no rope required. Hiking mostly
            on established routes. Involves some scrambling with the occasional
            use of hands.
          </li>
          <li>
            <b>2 Basic Canyoneering</b> Scrambling, easy vertical or near
            vertical climbing and/or downclimbing. Rope recommended for
            handlining, belays for novices, lowering packs and possible
            emergency use. Exit and/or retreat possible without ascending fixed
            ropes. Scrambling requires the user of hands and arms for pulling
            oneself up.
          </li>
          <li>
            <b>3 Intermediate Canyoneering</b> Technical canyoneering and
            climbing. Route may involve any combination of the following:
            Problem solving. A basic knowledge of technical climbing and
            chimneying. Rope and climbing hardware for single pitch rappels and
            belays. Basic pothole escape techniques. The use of obvious natural
            anchors for rappels. Retreating upcanyon will require ascending or
            jumarring up fixed ropes.
          </li>
          <li>
            <b>4 Advanced Canyoneering</b> In addition to intermediate
            canyoneering skills, this will require one or more of the following
            skills: Advanced free climbing. Difficult and/or exposed
            up/downclimbing. Climbing using direct aid. Multipitch rappels.
            Complex rope work (example--guided rappels, deviations, rebelays).
            Obscure or indistinct natural anchors. Advanced problem solving and
            anchor building. Advanced pothole escape techniques (example--pack
            toss or hooking out). At least one canyoneer has noted that
            introducing bolts into what originally would have been a 4 rated
            canyon (many in Zion NP), permanently reduces it to a 3.
          </li>
        </Ul>
        <H3 id="kelsey-water-rating">Water Rating</H3>
        <P>
          Indicates possible complications due to flowing, quiet or pothole
          water.
        </P>
        <Ul>
          <li>
            <b>A</b> Normally dry or very little water, usually in potholes.
            Dryfalls.
          </li>
          <li>
            <b>B</b> Normally has water with no current or light current. Still
            pools. Falls normally dry or running at a trickle.
          </li>
          <li>
            <b>C</b> Normally has water with strong current. Waterfalls.
          </li>
        </Ul>
        <H3 id="kelsey-risk-rating">Risk Rating</H3>
        <P>
          Indicates the presence of additional risk factors such as double
          rappels down a big wall, or the presence of keeper potholes.
        </P>
        <Ul>
          <li>
            <b>R Risky</b> One or more extraordinary risk factors exist that
            could complicate the descent. Solid technical skills and sound
            judgement are critical.
          </li>
          <li>
            <b>X Extreme</b> Multiple risk factors exist that will complicate
            the descent. Errors in technique or judgement will likely result in
            serious injury or death. Descent should only be attempted by expert
            canyoneers.
          </li>
        </Ul>
        <H3 id="kelsey-slot">Slot</H3>
        <Ul>
          <li>
            <b>PG SLOT Plenty Good</b> but not life threatening. Will involve
            simple dowmclimbing or low-stemming, but no extreme moves. Beginners
            may sweat a little, but it's all pure fun for the experienced.
          </li>
          <li>
            <b>
              R SLOT Some Risks, X SLOT (Extreme Risks) or XX SLOt (Double
              Extreme Risks)
            </b>{' '}
            Extra tight slots with strenuous up/downclimbing and high-stemming,
            in some cases up to 15 meters or more above the bottom. Likely will
            have wide silos to cross, straight/vertical walls without foot/hand
            holds, and sometimes covered with green, slippery moss.{' '}
            <b>For experts only</b>. XX slots would definitely be{' '}
            <b>life threatening</b> for one or more moves. These ratings are
            based on the most difficult move in each slot.
          </li>
          <li>
            To the above, some canyoneers are adding <b>+ or -</b> to each. If
            it's a little better/more difficult than a regular <b>PG</b>, then
            push it up part of a notch to <b>+PG SLOT</b>. Or if it's not quite
            a full X SLOT, make it an <b>-X SLOT</b>, or perhaps an{' '}
            <b>+R SLOT</b>. This should make it easier to hit the mark. Again,
            all subjective.
          </li>
        </Ul>
        <H3 id="kelsey-grade">Grade</H3>
        <P>Indicates the estimated time required to complete the hike.</P>
        <Ul>
          <li>
            <b>I</b> Short. A couple of hours.
          </li>
          <li>
            <b>II</b> Requires about half a day.
          </li>
          <li>
            <b>III</b> Will take most of a day.
          </li>
          <li>
            <b>IV</b> Expect a long day. Get up early, bring a headlamp & mylar
            space blanket. Possible bivouac.
          </li>
          <li>
            <b>V</b> More than one day. Normally done in two days.
          </li>
          <li>
            <b>VI</b> Two full days or more.
          </li>
        </Ul>

        <H2 id="van-tilburg-aca">
          American Canyoneering Association Rating System from Canyoneering:
          Beginning to Advanced Techniques
        </H2>
        <H3 id="van-tilburg-aca-rope-work">Rope Work</H3>
        <Ul>
          <li>
            <b>1</b> No rope required for normal descent, although a rope should
            always be carried for emergencies.
          </li>
          <li>
            <b>2</b> Only single-pitch rappels required for normal descent.
          </li>
          <li>
            <b>3</b> Complex rope work and/or multi-pitch rappels required.
          </li>
        </Ul>
        <H3 id="van-tilburg-aca-water">Water</H3>
        <H4>Volume/Current</H4>
        <Ul>
          <li>
            <b>1</b> No water (or very, very little water).
          </li>
          <li>
            <b>2</b> Water with no current or light current.
          </li>
          <li>
            <b>3</b> Water with strong current.
          </li>
        </Ul>
        <H4>Swimming</H4>
        <Ul>
          <li>
            <b>A</b> No swimming required.
          </li>
          <li>
            <b>B</b> Short and/or infrequent swimming required.
          </li>
          <li>
            <b>C</b> Long and/or frequent swimming required.
          </li>
        </Ul>
        <H3 id="van-tilburg-aca-time">Time</H3>
        <Ul>
          <li>
            <b>1</b> 1 to 3 hours.
          </li>
          <li>
            <b>2</b> 3 to 4 hours.
          </li>
          <li>
            <b>3</b> 3 to 6 hours.
          </li>
          <li>
            <b>4</b> Full day.
          </li>
          <li>
            <b>5</b> 1 to 2 days.
          </li>
          <li>
            <b>6</b> Multiple days in the canyon.
          </li>
        </Ul>
        <H3 id="van-tilburg-aca-exits">Exits</H3>
        <Ul>
          <li>
            <b>R</b> Some sections with limited exits.
          </li>
          <li>
            <b>X</b> Long sections with no possible exits.
          </li>
        </Ul>
        <H3 id="van-tilburg-aca-climbing">Climbing</H3>
        <P>
          Classes of terrain based on the system developed by the Sierra Club;
          technical climbing (Class 5) is defined more precisely using the
          Yosemite Decimal System.
        </P>
        <Ul>
          <li>
            <b>1</b> Walking on level ground.
          </li>
          <li>
            <b>2</b> Hiking on or off trail with some elevation change.
          </li>
          <li>
            <b>3</b> Scrambling; may require hands for balance and support.
          </li>
          <li>
            <b>4</b> Easy vertical or near vertical climbing
          </li>
          <li>
            <b>5</b> Technical climbing; further broken down by Yosemite Decimal
            System from 5.0 to 5.14
          </li>
          <li>
            <b>6</b> Aid climbing.
          </li>
        </Ul>

        <H2 id="van-tilburg">Canyoneering: Beginning to Advanced Techniques</H2>
        <H3 id="van-tilburg-1">Class 1: Canyon Hiking</H3>
        <Ul>
          <li>Usually dry hiking on established trail</li>
          <li>Routefinding straightforward using map and guidebook</li>
          <li>Established campsites available</li>
          <li>Hike to rim or backtracking straightforward</li>
          <li>Evacuation of injured person straightforward</li>
          <li>No major obstacles</li>
        </Ul>
        <H3 id="van-tilburg-2">Class 2: Advanced Canyon Hiking</H3>
        <Ul>
          <li>Dry hiking with some river rock or uneven terrain</li>
          <li>Streams easily crossed by ankle-deep wading or jumping</li>
          <li>Straightforward routefinding with map and guidebook</li>
          <li>Hike to rim or backtracking straightforward</li>
          <li>Suitable campsite available</li>
          <li>Evacuation of injured person straightforward</li>
          <li>Obstacles such as boulders easily circumvented</li>
        </Ul>
        <H3 id="van-tilburg-3">Class 3: Basic Canyoneering</H3>
        <Ul>
          <li>Prolonged water hiking and uneven terrain</li>
          <li>Stream crossings utilize downed logs, rocks, or wading</li>
          <li>
            Class 3 climbing such as scrambling over log jams may be necessary;
            may be able to circumvent
          </li>
          <li>Navigation and routefinding skills necessary</li>
          <li>Exits to rim sparse</li>
          <li>Backtracking may he difficult</li>
          <li>High and dry campsite more difficult to find</li>
          <li>
            Risks of flash flood, cold injury, drowning, rockfall more prominent
          </li>
          <li>Evacuation of injured person difficult</li>
        </Ul>
        <H3 id="van-tilburg-4">Class 4: Advanced Canyoneering</H3>
        <Ul>
          <li>
            Difficult hiking, multiple stream crossings, prolonged water walking
          </li>
          <li>Tight narrows may require taking off pack</li>
          <li>
            Plunge pools and wall-to-wall water may require swimming or deep
            wading
          </li>
          <li>Class 4 climbing with hand lines necessary in some places</li>
          <li>
            Circumventing log jams or chockstones may not be possible; may
            require bouldering skills
          </li>
          <li>Navigation and routefinding skills necessary</li>
          <li>Exit to rim difficult to find</li>
          <li>Backtracking difficult</li>
          <li>High and dry campsite difficult to find</li>
          <li>Risks of injury and environmental hazards more significant</li>
          <li>Evacuation of injured person difficult</li>
        </Ul>
        <H3 id="van-tilburg-5">Class 5: Technical Canyoneering</H3>
        <Ul>
          <li>
            Difficult and slow hiking, multiple stream crossings, prolonged
            water walking
          </li>
          <li>Tight narrows may require taking off pack</li>
          <li>
            Plunge pools and wall-to-wall water may require swimming across
          </li>
          <li>
            Class 5 climbing with hand lines and bouldering skills necessary
          </li>
          <li>
            Rappels necessary for dry pouroffs or waterfalls; anchors such as
            trees available
          </li>
          <li>Navigation and routefinding very difficult and time-consuming</li>
          <li>Exit to rim more difficult to find</li>
          <li>Backtracking very difficult</li>
          <li>High and dry campsite rare</li>
          <li>
            Combining of skills such as rappelling into a pool or in a waterfall
            needed frequently
          </li>
          <li>
            Risks of injury and environmental hazards significant; some risk of
            death
          </li>
          <li>
            Evacuation of injured person extremely difficult without outside
            help
          </li>
        </Ul>
        <H3 id="van-tilburg-6">Class 6: Advanced Technical Canyoneering</H3>
        <Ul>
          <li>Extremely difficult slow hiking and water walking</li>
          <li>Tight narrows may require taking off pack</li>
          <li>
            Long swims and difficult, technical stream crossings with hand lines
          </li>
          <li>Hand lines necessary for down climbing</li>
          <li>Rappels frequent; anchors not readily available</li>
          <li>Class 5.7 and above rock climbing</li>
          <li>Navigation and routefinding very difficult and time-consuming</li>
          <li>Secure, high and dry campsite may not be available</li>
          <li>
            Risks of injury and environmental hazards constant, and risk of
            death significant
          </li>
          <li>
            Combining of skills, such as rappelling into a pool or in a
            waterfall frequently necessary
          </li>
          <li>Exit to rim impossible</li>
          <li>Backtracking impossible without fixed lines</li>
          <li>Evacuation of injured person nearly impossible without help</li>
        </Ul>

        <hr />
        <p>
          Canyoneering: A Guide To Techniques For Wet And Dry Canyons (How To
          Climb Series) by David Black
        </p>
        <p>
          Technical Slot Canyon Guide to the Colorado Plateau 2nd Edition by
          Michael R. Kelsey
        </p>
        <p>
          Canyoneering: Beginning to Advanced Techniques by Christopher Van
          Tilburg
        </p>
      </Layout>
    );
  }
}

export default withFrontMatter('other-canyon-rating-systems')(
  Canyoneering_OtherCanyonRatingSystems
);
