import React from 'react';
import frontMatterMap from '../data/front-matter';

export const withFrontMatter = (id) => (WrappedComponent) => {
  class WithFrontMatter extends React.Component {
    frontMatter = {};

    constructor(props) {
      super(props);
      this.frontMatter = frontMatterMap[id];
    }

    render() {
      return (
        <WrappedComponent frontMatter={this.frontMatter} {...this.props} />
      );
    }
  }

  WithFrontMatter.displayName = `WithFrontMatter(${getDisplayName(
    WrappedComponent
  )})`;

  return WithFrontMatter;
};

export const getDisplayName = (WrappedComponent) => {
  return WrappedComponent.displayName || WrappedComponent.name || 'Component';
};
